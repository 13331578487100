<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-ilogin">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <logo public/>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import { popupCenter } from '@/util/Util'
import Logo from '@/layouts/components/Logo.vue'

import UserService from '@/services/api/UserService'

export default {
  components: {
    Logo,
  },
  data() {
    return {
      service: null
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.itoken !== ''
    }
  },
  methods: {
    loadData() {
      const itoken = this._.get(this.$route.query, 'itoken', [])

      this.service.loginByiToken(itoken).then(
        data => {

          const payload = {
              user: data,
              updateUsername: true,
              userDetails: {
                email: data.email,
              },
              notify: this.$vs.notify,
              closeAnimation: this.$vs.loading.close,
              acl: this.$acl,
              redirect: true
            }

          console.log('payload...', payload)
          this.$store.dispatch('auth/loginSuccess', payload)

        },
        error => {this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-essa-operacao'))}
      ).finally(() => {this.$vs.loading.close()})
    }
  },
  created() {
    this.smallScreen = this.isSmallScreen()
  },
  beforeMount() {
    this.service = UserService.build(this.$vs)
  },
  mounted() {
    window.addEventListener('message', this.loginListener, false)

    this.loadData()
  },

  beforeDestroy() {
    window.removeEventListener('message', this.loginListener, false)
  }
}
</script>
